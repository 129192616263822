import {
  Box,
  IconButton,
  useTheme,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { useContext, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { useAuth } from "../../Hooks/Autorizacion";
import axios from "axios"; // eslint-disable-line no-unused-vars

const Topbar = () => {
  const { logout } = useAuth();
  const [open, setOpen] = useState(false); // Estado para controlar si el menú está abierto
  const [anchorEl, setAnchorEl] = useState(null); // Estado para el anclaje del menú
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  // Función para abrir el menú
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Función para cerrar el menú
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box display="flex" backgroundColor={colors.grey[100]} borderRadius="3px">
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>

        {/* Ícono de Person con menú desplegable */}
        <IconButton onClick={handleMenuOpen}>
          <PersonOutlinedIcon />
        </IconButton>

        {/* Menú desplegable */}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleMenuClose}>Perfil</MenuItem>
          <MenuItem onClick={handleMenuClose}>Configuración</MenuItem>
          <MenuItem onClick={logout}>
              <Button
                className="btn btn-outline-warning"
                fullWidth
                sx={{ justifyContent: "flex-start",
                  color: "white",
                  vertical: "top",
                  horizontal: "right",
                  backgroundColor: colors.primary[100],
                  "&:hover": {
                    backgroundColor: colors.primary[200], // Un verde más oscuro al pasar el mouse
                  },
                 }}
              >
                Cerrar Sesión
              </Button>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
