import React, {useContext } from "react";
import {Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  useTheme} from "@mui/material";
import { ColorModeContext, tokens } from "../theme";

function StickyHeadTable ({ datos,
  columns,
  page,
  rowsPerPage, 
  setPage, 
  setRowsPerPage, 
  totalRows, 
  isAdding, 
  newRow,
  handleInputChange,
  handleAddRow,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  
  const handleChangePage = (event, newPage) => {
    setPage (newPage); 
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
      <Box display="flex" sx={{ pt: "10px", px: "2vh",}}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "63vh", maxWidth: "100%" }}>
            <Table stickyHeader aria-label="sticky table" sx={{tableLayout:"fixed"}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{
                        fontSize: "15px",
                        backgroundColor: colors.grey[300],
                      }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {/* Fila en modo edición */}
              {isAdding && (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newRow.Año}
                      onChange={(e) => handleInputChange("Año", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel>Trimestre</InputLabel>
                      <Select
                        value={newRow.Trimestre}
                        onChange={(e) =>
                          handleInputChange("Trimestre", e.target.value)
                        }
                        label="Trimestre"
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="datetime-local"
                      value={newRow.FechaAperturaPlataforma || ""}
                      onChange={(e) =>
                        handleInputChange(
                          "FechaAperturaPlataforma",
                          e.target.value
                        )
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="datetime-local"
                      value={newRow.FechaCierrePlataforma || ""}
                      onChange={(e) =>
                        handleInputChange(
                          "FechaCierrePlataforma",
                          e.target.value
                        )
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddRow}
                    >
                      Guardar
                    </Button>
                  </TableCell>
                </TableRow>
              )}

              {/* Filas existentes */}
              {datos.map((dato, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          const value = dato[column.id];
                          return (
                            <TableCell key={column.id} align={column.align} sx={{fontSize:"13px"}}>
                              {column.renderCell ? column.renderCell({ dato }) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={Math.min(page, Math.ceil(totalRows / rowsPerPage))}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por Pagina"
          />
        </Paper>
      </Box>
  );
};

export default StickyHeadTable;
