import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
} from "@mui/material";
import axios from "axios";
import StickyHeadTable from "../Componentes/StickyHeadTable"; // Importa el componente de la tabla
import { useAuth } from "../Hooks/Autorizacion";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const AdminTrimestre = () => {
  const user = useAuth();
  const [datos, setDatos] = useState([]);
  const { mostrarAlerta } = useAuth();
  const [MensajeDeAlerta, setMensajeDeAlerta] = useState("");
  const [paginaActual, setPaginaActual] = useState(0); // Estado para la página actual
  const [elementosPorPagina, setElementosPorPagina] = useState(10); // Estado para filas por página
  const [totalRows, setTotalRows] = useState(0); // Total de filas
  const [añoLabel, setAñoLabel] = React.useState("");
  const [trimestreLabel, setTrimestreLabel] = React.useState("");
  const [isAdding, setIsAdding] = useState(false); // Estado para controlar si se está agregando una fila
  const añoActual = new Date().getFullYear();
  const [newRow, setNewRow] = useState({
    Año: añoActual,
    Trimestre: "",
    FechaAperturaPlataforma: null,
    FechaCierrePlataforma: null,
    Activo: 0,
  });

  const fetchData = async () => {
    try {
      const response = await axios.get("/AdministrarTrimestre", {
        params: {
           page:paginaActual,
           perPage: elementosPorPagina,
           correo: {
            user: user.user.username,
          },
        },
        withCredentials: true,
      }
      );
      setDatos(response.data.data);
      setTotalRows(response.data.total);
    } catch (error) {
      console.log("Error al obtener los datos: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [paginaActual, elementosPorPagina]);

  const handleInputChange = (field, value) => {
    setNewRow((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddRow = async () => {
    try {
      // Validar los datos
      if (
        !newRow.Año ||
        !newRow.Trimestre ||
        !newRow.FechaAperturaPlataforma ||
        !newRow.FechaCierrePlataforma
      ) {
        mostrarAlerta({
          mensaje: "Todos los campos son requeridos.",
          severity: "info",
          mostrar: true,
          title: "info",
        });
        return;
      }

      if (![1, 2, 3, 4].includes(Number(newRow.Trimestre))) {
        setMensajeDeAlerta("El trimestre debe ser un número entre 1 y 4.");
        mostrarAlerta({
          mensaje: MensajeDeAlerta,
          severity: "warning",
          mostrar: true,
          title: "Warning",
        });
        return;
      }

      await axios.post(
        "/AdministrarTrimestre/Agregar",
        {
          newRow,
          correo: {
           user: user.user.username,
           },
        },
        {withCredentials: true}
      );
      fetchData(); // Refresca los datos después de agregar
      setIsAdding(false); // Sale del modo edición
      setNewRow({
        Año: añoActual,
        Trimestre: null,
        FechaAperturaPlataforma: null,
        FechaCierrePlataforma: null,
        Activo: false,
      }); // Limpia el formulario
      // Mostrar mensaje de error al usuario
      mostrarAlerta({
        mensaje: "Trimestre creado correctamente.",
        severity: "succes",
        mostrar: true,
        title: "Success",
      });
    } catch (error) {
      console.error("Error al agregar el trimestre:", error);
    }
  };

  const handleDeleteRow = async (dato) => {
    try {
      // Mostrar confirmación antes de eliminar
      const isConfirmed = window.confirm(
        `¿Está seguro que desea eliminar el trimestre ${dato.Año} - Trimestre ${dato.Trimestre}?`
      );

      if (!isConfirmed) {
        return; // Si el usuario cancela, no hacer nada
      }
      await axios.delete(
        "/AdministrarTrimestre/Eliminar",
        {
          data: { 
            Año: dato.Año, 
            Trimestre: dato.Trimestre, // Enviar los datos en el cuerpo de la solicitud
          correo: {
            user: user.user.username,
          },
        },
        withCredentials: true,
      }
      );

      // Refrescar los datos después de eliminar
      fetchData();

      // Mostrar mensaje de éxito
      mostrarAlerta({
        mensaje: "Trimestre eliminado correctamente",
        severity: "success",
        mostrar: true,
        title: "Success",
      });
    } catch (error) {
      console.error(
        "Error al eliminar el trimestre:",
        error.response?.data || error.message
      );

      // Mostrar mensaje de error al usuario
      mostrarAlerta({
        mensaje:
          "Ocurrió un error al eliminar el trimestre: Verifica si el trimestre está activo",
        severity: "warning",
        mostrar: true,
        title: "Warning",
      });
    }
  };

  const handleEnable = async (row) => {
    try {
      // Verificar si ya hay un trimestre activo
      const response = await axios.get(
        "/AdministrarTrimestre/Validar"
      );

      //ESTO SE CAMBIA POR POR UN WARNING
      if (response.data.error) {
        alert(
          `${response.data.error}\nAño: ${response.data.trimestreActivo.Año}, Trimestre: ${response.data.trimestreActivo.Trimestre}`
        );
      }
      // Habilitar el trimestre
      await axios.put("/AdministrarTrimestre/Habilitar/", 
        {
        Año: row.Año,
        Trimestre: row.Trimestre,
        correo: {
          user: user.user.username,
        },  
      },
      {withCredentials: true}
    );
      fetchData(); // Refrescar los datos después de habilitar
    } catch (error) {
      console.error("Error al habilitar el trimestre:", error);
      mostrarAlerta({
        mensaje: "¡Parece que hay otro trimestre Habilitado!",
        severity: "warning",
        mostrar: true,
        title: "Warning",
      });
    }
  };

  // Función para inhabilitar un trimestre
  const handleDisable = async (row) => {
    try {
      // Llamar al backend para actualizar el estado de Activo
      await axios.put(
        `/AdministrarTrimestre/Inhabilitar`,
        {
          Año: row.Año,
          Trimestre: row.Trimestre,
          correo: {
            user: user.user.username,
          },  
        },
        {withCredentials: true}
      );

      // Refrescar los datos en el frontend
      fetchData();
    } catch (error) {
      console.error("Error al inhabilitar el trimestre:", error);
      mostrarAlerta({
        mensaje: "Ocurrió un error al deshabilitar el trimestre.",
        severity: "error",
        mostrar: true,
        title: "Error",
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "año") {
      setAñoLabel(value); // Actualiza el estado del año
    } else if (name === "trimestre") {
      setTrimestreLabel(value); // Actualiza el estado del trimestre
    }
  };

  const handleGenerarReporte = async () => {
    try {
      // Validar que ambos campos estén seleccionados
      if (!añoLabel || !trimestreLabel) {
        mostrarAlerta({
          mensaje: "Por favor, seleccione un año y un trimestre.",
          severity: "info",
          mostrar: true,
          title: "Info",
        });
        return;
      }

      //Enviar los datos al backend
      const response = await axios.get(
        "/GenerarEstadoCargue",
        {
          params: {
            año: añoLabel,
            trimestre: trimestreLabel,
          },
          responseType: "blob",
          withCredentials: true,
        }
      );
      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${añoLabel}-0${trimestreLabel}.xlsx`); // Nombre del archivo
      document.body.appendChild(link);
      link.click();
      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
      mostrarAlerta({mensaje: `Se ha generado el reporte exitosamente.`, severity: "success", mostrar: true, title: "Success"});
      // Manejar la respuesta del backend
    } catch (error) {
      // Verificar si la respuesta es un Blob
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          try {
            // Intentar parsear el Blob como JSON
            const errorData = JSON.parse(reader.result);
            mostrarAlerta({
              mensaje: errorData.message || "Error desconocido",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          } catch (e) {
            // Si no se puede parsear como JSON, mostrar un mensaje genérico
            mostrarAlerta({
              mensaje: "Error al procesar la respuesta del servidor",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          }
        };

        reader.readAsText(error.response.data); // Leer el Blob como texto
      } else {
        // Si no es un Blob, mostrar un mensaje genérico
        mostrarAlerta({
          mensaje: error.response?.data?.message || "Error desconocido",
          severity: "error",
          mostrar: true,
          title: "Error",
        });
      }
    }
  };

  const columns = [
    { id: "Año", label: "Año", minWidth: 170 },
    { id: "Trimestre", label: "Trimestre", minWidth: 170 },
    {
      id: "FechaAperturaPlataforma",
      label: "Fecha Apertura Plataforma",
      minWidth: 170,
    },
    {
      id: "FechaCierrePlataforma",
      label: "Fecha Cierre Plataforma",
      minWidth: 170,
    },
    {
      id: "Activo",
      label: "Habilitar/Inhabilitar",
      renderCell: ({ dato }) => {
        const activo = dato.Activo; // Obtener el valor de Activo desde la fila
        return (
          <Box>
            {activo === true ? (
              <Button
                variant="contained"
                color="error"
                startIcon={<RemoveCircleOutlineIcon />}
                onClick={() => handleDisable(dato)}
              >
                Inhabilitar
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                startIcon={<CheckCircleIcon />}
                onClick={() => handleEnable(dato)}
              >
                Habilitar
              </Button>
            )}
            <IconButton
              color="error"
              aria-label="delete"
              onClick={() => handleDeleteRow(dato)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return (
    <Box>
      {/* Título */}
      <Typography variant="h1" component="h2" textAlign={"center"}>
        Gestión de Trimestres
      </Typography>
      <Stack
        display="flex"
        direction="row"
        justifyContent={"space-between"}
        sx={{ pt: "10px", px: "3vh" }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel id="demo-simple-select-label">Año</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={añoLabel}
              label="Año"
              name="año"
              onChange={handleChange}
            >
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
              <MenuItem value={2026}>2026</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel id="demo-simple-select-label">Trimestre</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={trimestreLabel}
              label="Trimestre"
              name="trimestre"
              onChange={handleChange}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </FormControl>
          {/* Botón para generar reporte */}
          <Button
            variant="contained"
            endIcon={<AssignmentReturnedIcon />}
            onClick={handleGenerarReporte}
            sx={{ p: "1.8vh" }}
          >
            Generar reporte
          </Button>
        </Stack>
        {/* Botón para agregar un trimestre */}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsAdding(true)}
        >
          Agregar Trimestre
        </Button>
      </Stack>

      {/* Componente de la tabla */}
      <StickyHeadTable
        datos={datos} // Pasa los datos obtenidos a la tabla
        columns={columns}
        page={paginaActual}
        rowsPerPage={elementosPorPagina}
        setPage={setPaginaActual}
        setRowsPerPage={setElementosPorPagina}
        totalRows={totalRows}
        isAdding={isAdding}
        newRow={newRow}
        handleAddRow={handleAddRow}
        handleInputChange={handleInputChange}
      />
    </Box>
  );
};
export default AdminTrimestre;
