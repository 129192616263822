import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/Autorizacion";
import { useState } from "react";
import * as asn1js from "asn1js";
import * as pkijs from "pkijs";
import axios from "axios";
import useFetchEstado from "../Hooks/EstadoCargue";
import { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Subir = () => {
  const user = useAuth();
  const {mostrarAlerta} = useAuth();
  const Navigate = useNavigate();
  const [archivoSeleccionado, setArchivoSeleccionado] = useState(null);
  const [AlertaVisible, setAlertaVisible] = useState(false);
  const [MensajeDeAlerta, setMensajeDeAlerta] = useState("");
  const [BotonVisible, setBotonVisible] = useState(false);
  const [MostrarValidado, setMostrarValidado] = useState(false);
  const { estado, isLoading, NombreMuni } = useFetchEstado(user);
  const [DesactivarForm, setDesactivarForm] = useState(true);
  const [AlertaEstadosVisible, setAlertaEstadosVisible] = useState(false);
  const [BotonPDF, setBotonPDF] = useState(true);
  const [mostrarSpinner, setMostrarSpinner] = useState(true);
  const [boxValidar, setBoxValidar] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // Si el estado es 2, mostrar un mensaje del archivo siendo validado
    switch (estado) {
      case 1:
      case 2:
      case 3:
        setDesactivarForm(true);
        setAlertaEstadosVisible(true);
        mostrarAlerta({mensaje: "No puedes acceder a esta sección hasta que no termines las validaciones", severity: "warning", mostrar: true, title: "Warning"});
        break;
      case 4:
        setDesactivarForm(false);
        setAlertaEstadosVisible(false);
        break;
      case 5:
        setDesactivarForm(true);
        setBotonPDF(false);
        setBoxValidar("descargarPDF");
        setMostrarValidado(false);
        mostrarAlerta({mensaje: "Has completado el reporte PEDT exítosamente", severity: "success", mostrar: true, title: "Success"});
        break;
      default:
        // Si el estado no coincide con ninguno de los casos anteriores
        // No hay necesidad de hacer nada
        break;
    }
  }, [estado]);

  //Controlador para cuando se cambie el archivo seleccionado
  const handleFileChange = async (event) => {
    const archivo = event.target.files[0];
    setArchivoSeleccionado(archivo);
    // Verificar si se ha seleccionado un archivo
    if (archivo) {
      try {
        const fechaCreacion = obtenerFechaCreacion(archivo);
        const InfoCertificado = await DecodificarArchivoP7z(archivo);
        if (InfoCertificado) {
          const { certificados, decodedString } = InfoCertificado;

          let certificadoMasPesado;
          if (certificados.length <= 3) {
            // Si hay tres o menos certificados, obtenemos el más pesado
            certificadoMasPesado = certificados[0];
            for (let i = 1; i < certificados.length; i++) {
              if (
                certificados[i].tbsView.length >
                certificadoMasPesado.tbsView.length
              ) {
                certificadoMasPesado = certificados[i];
              }
            }
          } else {
            // Si hay más de tres certificados, obtenemos el segundo más pesado
            // Creamos una copia del array para evitar modificar el original
            const certificadosOrdenados = [...certificados];
            // Ordenamos los certificados por tamaño de forma descendente
            certificadosOrdenados.sort(
              (a, b) => b.tbsView.length - a.tbsView.length
            );
            // El segundo certificado más pesado será el que esté en la posición 1 del array ordenado
            certificadoMasPesado = certificadosOrdenados[1];
          }
          const DatosCertificado = ObtenerInfoCertificado(certificadoMasPesado);
          try {
            const nombreArchivo = archivo.name;
            const response = await axios.post(
              "/Validarfirma",
              {
                Fechafirma: fechaCreacion,
                FechafinVigencia: certificadoMasPesado.notAfter,
                IDRes: DatosCertificado.nIdTitular,
                Contenido: decodedString,
                Usuario: user.user.username,
                NombreArchivo: nombreArchivo,
              }
            );
            if (response.data.success) {
              setMostrarValidado(true);
              setAlertaVisible(true);
              mostrarAlerta({mensaje: response.data.message, severity: "success", mostrar: AlertaVisible, title: "Success"});
              setBotonVisible(true);
            } else {
              setMostrarValidado(false);
              mostrarAlerta({mensaje: response.data.message, severity: "error", mostrar: AlertaVisible, title: "Error"});
              setAlertaVisible(true);
              setBotonVisible(false);
            }
          } catch (error) {
            setMostrarValidado(false);
            if (error.response) {
              // Error de respuesta del servidor
              const errorMessage =
                error.response.data.message ||
                "Ha ocurrido un error en el servidor.";
              setAlertaVisible(true);
              mostrarAlerta({mensaje: errorMessage, severity: "error", mostrar: AlertaVisible, title: "Error"});
            } else if (error.request) {
              // Error de solicitud
              setAlertaVisible(true);
              mostrarAlerta({mensaje: "No se pudo conectar al servidor", severity: "error", mostrar: AlertaVisible, title: "Error"});
            } else {
              // Otros errores
              setAlertaVisible(true);
              mostrarAlerta({mensaje: "Ha ocurrido un error. Por favor, inténtelo de nuevo más tarde.", severity: "error", mostrar: AlertaVisible, title: "Error"});
            }
          }
        } else {
          setAlertaVisible(true);
          setMostrarValidado(false);
          setBotonVisible(false);
          mostrarAlerta({mensaje: "El archivo está corrupto", severity: "error", mostrar: AlertaVisible, title: "Error"});
        }
      } catch (error) {
        setAlertaVisible(true);
        setMostrarValidado(false);
        setBotonVisible(false);
        mostrarAlerta({mensaje: "El archivo está corrupto", severity: "error", mostrar: AlertaVisible, title: "Error"});
      }
    } else {
      setAlertaVisible(true);
      setMostrarValidado(false);
      setBotonVisible(false);
      mostrarAlerta({mensaje: "No se ha seleccionado ningún archivo !!!", severity: "error", mostrar: AlertaVisible, title: "Error"});
    }
  };

  //Obtener la fecha de la creacion del archivo
  const obtenerFechaCreacion = (archivo) => {
    return archivo.lastModifiedDate; // Devuelve la fecha de última modificación del archivo
  };

  // Función para obtener el nombre y el número de identificación del titular del certificado
  const ObtenerInfoCertificado = (certificado) => {
    const NombreTitular = certificado.subject.typesAndValues.find(
      (item) => item.type === "2.5.4.3"
    );
    const NombreEntidadCertificadora = certificado.issuer.typesAndValues.find(
      (item) => item.type === "2.5.4.3"
    );
    let TipoOIDNITMunicipio;
    let TipoOIDTitular;
    let TipoOIDCertificadora;
    switch (NombreEntidadCertificadora.value.valueBlock.value) {
      case ("AC SUB CERTICAMARA", "AC SUB 4096 CERTICAMARA"):
        TipoOIDTitular = "1.3.6.1.4.1.23267.2.2";
        TipoOIDCertificadora = "2.5.4.11";
        TipoOIDNITMunicipio = "1.3.6.1.4.1.23267.2.3";
        break;
      case "CAMERFIRMA COLOMBIA SAS CERTIFICADOS - 002":
        TipoOIDTitular = "2.5.4.5";
        TipoOIDCertificadora = "2.5.4.5";
        TipoOIDNITMunicipio = "2.5.4.97";
        break;
      default:
        TipoOIDTitular = "2.5.4.5";
        TipoOIDCertificadora = "2.5.4.11";
        TipoOIDNITMunicipio = "1.3.6.1.4.1.4710.1.3.2";
        break;
    }
    const NITMunicipioTitular = certificado.subject.typesAndValues.find(
      (item) => item.type === TipoOIDNITMunicipio
    );
    const IdentificacionTitular = certificado.subject.typesAndValues.find(
      (item) => item.type === TipoOIDTitular
    );
    const IdentificacionEntidadCertificadora =
      certificado.issuer.typesAndValues.find(
        (item) => item.type === TipoOIDCertificadora
      );
    const nTitular = NombreTitular
      ? NombreTitular.value.valueBlock.value
      : "No disponible";
    const cNit = NITMunicipioTitular
      ? NITMunicipioTitular.value.valueBlock.value
      : "No disponible";
    const nIdTitular = IdentificacionTitular
      ? IdentificacionTitular.value.valueBlock.value
      : "No disponible";
    const nCertificadora = NombreEntidadCertificadora
      ? NombreEntidadCertificadora.value.valueBlock.value
      : "No disponible";
    const nIdCertificadora = IdentificacionEntidadCertificadora
      ? IdentificacionEntidadCertificadora.value.valueBlock.value
      : "No disponible";
    return { nTitular, cNit, nIdTitular, nCertificadora, nIdCertificadora };
  };

  //Manejo del botón del formulario
  const ManejoSubmit = async () => {
    try {
      // Mostrar el spinner
      setMostrarSpinner(false);
      setAlertaEstadosVisible(true);
      mostrarAlerta({mensaje: "Subiendo registros, por favor espere, esto puede tardar unos segundos, no salga de esta sección hasta que termine el proceso...", severity: "info", mostrar: AlertaVisible, title: "Info"});
      setDesactivarForm(true);
      setBotonVisible(false);
      const formData = new FormData(); // Crea un objeto FormData
      formData.append("archivo", archivoSeleccionado);
      const response = await axios.post(
        "/Entrega",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Establece el encabezado adecuado para el envío de archivos
          },
          params: {
            Usuario: user.user.username, // Adjunta otros datos necesarios en la URL
          },
        }
      );
      if (response.data.success) {
        setDesactivarForm(true);
        setBotonVisible(false);
        setMostrarValidado(true);
        setBotonPDF(false);
        setAlertaVisible(true);
        mostrarAlerta({mensaje: "Se envió el archivo firmado correctamente", severity: "success", mostrar: AlertaVisible, title: "Success"});
        setBoxValidar("descargarPDF");
      }
    } catch (error) {
      setMensajeDeAlerta("Ha ocurrido un error en el servidor");
      setAlertaVisible(true);
      mostrarAlerta({mensaje: "Ha ocurrido un error en el servidor", severity: "error", mostrar: AlertaVisible, title: "Error"});
    } finally {
      // Ocultar el spinner una vez que se complete la entrega
      setMostrarSpinner(true);
    }
  };

  const DescargaPDF = async () => {
    try {
      const response = await axios.get("/BajarPDF", {
        params: {
          correo: {
            user: user.user.username,
          },
        },
        responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
      });

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "EntregaPEDT.pdf"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // Verificar si la respuesta es un Blob
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          try {
            // Intentar parsear el Blob como JSON
            const errorData = JSON.parse(reader.result);
            mostrarAlerta({
              mensaje: errorData.message || "Error desconocido",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          } catch (e) {
            // Si no se puede parsear como JSON, mostrar un mensaje genérico
            mostrarAlerta({
              mensaje: "Error al procesar la respuesta del servidor",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          }
        };

        reader.readAsText(error.response.data); // Leer el Blob como texto
      } else {
        // Si no es un Blob, mostrar un mensaje genérico
        mostrarAlerta({
          mensaje: error.response?.data?.message || "Error desconocido",
          severity: "error",
          mostrar: true,
          title: "Error",
        });
      }
    }
  };

  const DecodificarArchivoP7z = async (ArchivoP7z) => {
    try {
      // Lee el archivo .p7z
      const arrayBuffer = await ArchivoP7z.arrayBuffer();

      // Parsea el contenido ASN.1
      const asn1 = asn1js.fromBER(arrayBuffer);
      const cms = new pkijs.ContentInfo({ schema: asn1.result });

      // Verifica que el contenido es una instancia de SignedData
      if (cms.contentType !== "1.2.840.113549.1.7.2") {
        throw new Error("El contenido del archivo no está firmado");
      }

      // Obtiene el contenido firmado
      const signedData = new pkijs.SignedData({ schema: cms.content });

      // Obtener los certificados utilizados para firmar
      const certificados = signedData.certificates;

      // Obtener el contenido encapsulado
      const encapsulatedContentData =
        signedData.encapContentInfo.eContent.valueBeforeDecode;

      // Decodificar el ArrayBuffer a una cadena legible
      const textDecoder = new TextDecoder();
      let decodedString = textDecoder.decode(encapsulatedContentData);

      // Eliminar caracteres no deseados al principio hasta que se encuentre un '1|05'
      const posicion = decodedString.indexOf("1|05");
      if (posicion !== -1) {
        decodedString = decodedString.substring(posicion);
      }

      return { certificados, decodedString };
    } catch (error) {
      setAlertaVisible(true);
      setMostrarValidado(false);
      setBotonVisible(false);
      mostrarAlerta({mensaje: "El archivo está corrupto", severity: "error", mostrar: AlertaVisible, title: "Error"});
      return null;
    }
  };

  const BoxValidar = ({ tipo }) => {
    const renderBox = () => {
      switch (tipo) {
        case "descargarPDF":
          return (
            <Box
              sx={{
                display: "flex",
                bgcolor: colors.grey[200],
                component: "section",
                flexDirection: "column",
                color: colors.grey[900],
                alignItems: "center",
                borderRadius: 15,
                boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h1"
                marginX={8}
                marginY={5}
                textAlign={"center"}
              >
                Descargar Archivo PDF
              </Typography>

              <Typography variant="h5">
                Municipio a reportar: {NombreMuni}
              </Typography>

              <Box
                hidden={BotonPDF}
                component={Button}
                onClick={DescargaPDF}
                sx={{
                  border: 5,
                  p: 8,
                  mt: 2,
                  mb: 3.5,
                  borderRadius: 10,
                  border: "2px dashed rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  borderColor: colors.grey[900],
                  color: colors.grey[900],
                  "&:hover": {
                    borderColor: colors.grey[100], // Cambia el color del borde al hacer hover
                    color: colors.grey[100], // Cambia el color del texto al hacer hover
                    backgroundColor: colors.grey[600], // Cambia el fondo si lo deseas
                  },
                }}
              >
                {<CloudDownloadIcon fontSize="large" />}
                <Typography
                  marginLeft={2}
                  marginTop={1}
                  variant="h5"
                  display={"flex"}
                >
                  {"Clic aquí para descargar archivo PDF"}
                </Typography>
              </Box>
            </Box>
          );

        default:
          return (
            <Box
              sx={{
                display: "flex",
                bgcolor: colors.grey[200],
                component: "section",
                flexDirection: "column",
                color: colors.grey[900],
                alignItems: "center",
                borderRadius: 15,
                boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h1"
                marginX={8}
                marginY={5}
                textAlign={"center"}
              >
                Subir archivos con los reportes firmados
              </Typography>

              <Typography variant="h5">
                Municipio a reportar: {NombreMuni}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<UploadFileOutlinedIcon />}
                sx={{ m: 1 }}
                onClick={ManejoSubmit}
                hidden={!BotonVisible}
              >
                {"Realizar entrega"}
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudDownloadIcon />}
                sx={{ m: 1 }}
                onClick={DescargaPDF}
                hidden={BotonPDF}
              >
                {"Clic aquí para descargar PDF"}
              </Button>

              <Box
                hidden={DesactivarForm}
                component={Button}
                sx={{
                  border: 5,
                  p: 8,
                  mt: 2,
                  mb: 3.5,
                  borderRadius: 10,
                  border: "2px dashed rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  borderColor: colors.grey[900],
                  color: colors.grey[900],
                  "&:hover": {
                    borderColor: colors.grey[100], // Cambia el color del borde al hacer hover
                    color: colors.grey[100], // Cambia el color del texto al hacer hover
                    backgroundColor: colors.grey[600], // Cambia el fondo si lo deseas
                  },
                }}
              >
                <input
                  id="archivotxt"
                  type="file"
                  onChange={handleFileChange}
                  accept=".p7z, .firm"
                  hidden={DesactivarForm}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0, // Hace que el input sea transparente
                    cursor: "pointer", // Cambia el cursor para indicar que es clickeable
                  }}
                />
                {<CloudUploadIcon fontSize="large" />}
                <Typography
                  marginLeft={2}
                  marginTop={1}
                  variant="h5"
                  display={"flex"}
                >
                  {archivoSeleccionado
                    ? archivoSeleccionado.name
                    : "Arrastra o sube el archivo aqui"}
                </Typography>

                <CircularProgress
                  sx={{ marginRight: 17, marginLeft: 17, mb: 2 }}
                  color="#fff"
                  hidden={mostrarSpinner}
                />

                <Typography hidden={mostrarSpinner} variant="h5">
                  {"Esto puede tardar un momento ..."}
                </Typography>
              </Box>
            </Box>
          );
      }
    };
    return renderBox();
  };

  return (
    <Box
      display="flex"
      height="100vh"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      overflow="auto"
    >
      <Box display="flex" justifyContent={"center"} mb={3}>
        <img src={colors.logo[100]} alt="Logo" className="w-25" />
      </Box>

      <BoxValidar tipo={boxValidar} />
    </Box>
  );
};

export default Subir;
