import {
  Alert,
  AlertTitle,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../theme";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [user, setUser] = useState(null);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("");
  const [mostrarMensaje, setMostrarMensaje] = useState(false);
  const [titleMensaje, setMensajeTitle] = useState("");
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false); // Agrega una bandera para rastrear el estado de inicio de sesión

  const mostrarAlerta = (alert) => {
    setMensaje(alert.mensaje);
    setSeverity(alert.severity);
    setMostrarMensaje(alert.mostrar);
    setMensajeTitle(alert.title);
  };

  const login = async (data) => {
    setUser(data);
    navigate("/Validar", { replace: true });
    setIsLoggedIn(true); // Establece la bandera de inicio de sesión como verdadera al iniciar sesión
  };

  //Verifica la authenticación del usuario
  const checkAuth = async () => {
    if (!user) {
      try {
        const response = await axios.post(
          "/validateToken",
          {},
          {
            withCredentials: true, // Incluye las cookies en la solicitud
          }
        );
        if (response.status === 200) {
          const userData = response.data.user;
          setUser(userData);
          setIsLoggedIn(true);
        } else {
          logout();
        }
      } catch (error) {
        setSeverity("error");
        setMensajeTitle("Error");
        setMostrarMensaje(true);
        setMensaje("Error al verificar la autenticación");
        setUser(null);
        setIsLoggedIn(false);
        console.error("Error al verificar la autenticación:", error);
      }
    }
  };

  // Verificar la autenticación al cargar la aplicación
  useEffect(() => {
    checkAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logout = async () => {
    try {
      const response = await axios.post(
        "/logout",
        {},
        { withCredentials: true }
      );
      setMensaje(response.data.message);
      setMostrarMensaje(true);
      setSeverity("success");
      setUser(null);
      navigate("", { replace: true });
      setIsLoggedIn(false); // Establece la bandera de inicio de sesión como falsa al cerrar sesión
    } catch (error) {
      console.error(error);
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      isLoggedIn,
      mostrarAlerta,
    }),
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

      <Snackbar
        sx={{ mt: 4, ml: 5 }}
        open={mostrarMensaje}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setMostrarMensaje(false)}
      >
        <Alert
          sx={{
            color: "#fff",
            bgcolor: colors.greenAccent[600],
          }}
          variant="filled"
          severity={severity}
        >
          <AlertTitle variant="h4">{titleMensaje}</AlertTitle>
          <Typography variant="h6">{mensaje}</Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
