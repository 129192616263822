import { useEffect, useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAuth } from "../../Hooks/Autorizacion";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

const Item = ({ title, to, icon, selected, setSelected, subItems, hidden }) => {
  const [open, setOpen] = useState(false); // Estado para controlar si el menú está abierto
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleClick = () => {
    if (subItems) {
      setOpen(!open); // Alternar entre abierto y cerrado si hay subítems
    } else {
      setSelected(title); // Marcar como seleccionado si no tiene subítems
      navigate(to); // Redirigir si no hay subítems
    }
  };
  return (
    <>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
          textDecoration: "none",
          width: "100%",
        }}
        onClick={handleClick}
        icon={icon}
        hidden={hidden}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography>{title}</Typography>
          {subItems && (open ? <ExpandLess /> : <ExpandMore />)}
        </Box>
      </MenuItem>

      {/* Renderizar subítems si existen */}
      {subItems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((subItem, index) => (
              <ListItem
                key={index}
                component={Link}
                to={subItem.to}
                style={{
                  paddingLeft: theme.spacing(4), // Indentación para los subítems
                  color: colors.grey[100],
                  backgroundColor:
                    selected === subItem.title
                      ? colors.primary[400]
                      : "transparent",
                }}
                onClick={() => setSelected(subItem.title)}
              >
                <ListItemText primary={subItem.title} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const Sidebar = () => {
  const user = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [imageSrc, setImageSrc] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const checkImage = async (username) => {
    const extensions = ["jpg", "png", "jpeg"];
    for (const ext of extensions) {
      // Codifica el nombre de usuario para manejar espacios y caracteres especiales
      const encodedUsername = encodeURIComponent(username);
      const imageUrl = `/assets/${encodedUsername}.${ext}`;
      try {
        const response = await fetch(imageUrl);
  
        // Verifica que el contenido sea realmente una imagen
        const contentType = response.headers.get("content-type");
        if (response.ok && contentType && contentType.startsWith("image")) {
          setImageSrc(imageUrl);
          return imageUrl; // Si la imagen existe, retorna la URL
        }
        else setImageSrc("/assets/antioquia.png"); // Imagen por defecto
      } catch (error) {
        console.error(`Error al cargar ${imageUrl}`, error);
      }
    }
  };

  useEffect(() => {
    if (user.user != null) {
      let username = "";

      if (user.user.role === 1 || user.user.username.startsWith("U")) {
        username = "antioquia";
      } else {
        username = user.user.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      }

      checkImage(username);
    }
  }, [user.user]); // Solo se ejecuta cuando `user.user` cambia

  if (user.user != null) {
    let roleName = "";
    let aplicativo = "";

    if (user.user.role === 1) roleName = "ADMIN";
    else if (user.user.role === 2) roleName = "MUNICIPIO";
    else roleName = "ESE";

    if (user.user.username.startsWith("U")) aplicativo = "ESES";
    else aplicativo = "PEDT";

    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <ProSidebar
          collapsed={isCollapsed}
          backgroundColor={colors.primary[900]}
          style={{
            height: "100vh", // Ocupa el alto completo de la pantalla
            borderRight: "none",
            overflowY: "auto", // Permite el desplazamiento vertical si es necesario
          }} // Elimina el borde derecho
        >
          <Menu
            menuItemStyles={{
              button: {
                // Estilos base para los elementos del menú
                padding: "5px 35px 5px 20px !important",
                backgroundColor: "transparent !important",
                "&:hover": {
                  color: "#868dfb !important",
                },
                "&.active": {
                  color: "#868dfb !important",
                },
              },
            }}
          >
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography variant="h3" color={colors.grey[100]}>
                    {aplicativo}
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon sx={{ color: colors.grey[100] }} />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!isCollapsed && (
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    src={imageSrc}
                    style={{
                      cursor: "pointer",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {user.user.name}
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]}>
                    {roleName}
                  </Typography>
                </Box>
              </Box>
            )}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                hidden={true}
              />

              <Item
                title="Consolidado"
                to="/Auditoria"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                hidden={roleName !== "ADMIN"}
              />

              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
                hidden={roleName !== "MUNICIPIO"}
              >
                Gestión Archivos
              </Typography>
              <Item
                title="Validar"
                to="/Validar"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                hidden={roleName !== "MUNICIPIO"}
              />
              <Item
                title="SubirFirmado"
                to="/Subir"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                hidden={roleName !== "MUNICIPIO"}
              />
              {/*<Item
              title="Invoices Balances"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />*/}

              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
                hidden={aplicativo !== "PEDT"}
              >
                Pages
              </Typography>
              <Item
                title="Consultar Tablas"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                hidden={aplicativo !== "PEDT"}
                subItems={[
                  { title: "Ocupaciones", to: "/Ocupaciones" },
                  { title: "Prestadoras de Salud", to: "/PrestadorasDeSalud" },
                  { title: "PPNA", to: "/PPNA" },
                ]}
              />
              <Item
                title="Estado de cargue"
                to="/ConsultarEstadoCargue"
                icon={<PublishedWithChangesIcon />}
                selected={selected}
                setSelected={setSelected}
                hidden={aplicativo !== "PEDT"}
              />
              <Item
                title="Administrar Trimestres"
                to="/AdministrarTrimestre"
                icon={<EditCalendarIcon />}
                selected={selected}
                setSelected={setSelected}
                hidden={roleName !== "ADMIN"}
              />
              {/*
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />*/}
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    );
  }
};

export default Sidebar;
