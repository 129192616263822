import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import StickyHeadTable from "../Componentes/StickyHeadTable"; 

const PrestadorasDeSalud = () => {
  const [datos, setDatos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(0); // Estado para la página actual
  const [elementosPorPagina, setElementosPorPagina] = useState(10); // Estado para filas por página
  const [totalRows, setTotalRows] = useState(0); // Total de filas
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const columns = [
    { id: "CodHabilitacion", label: "Código de Habilitación", minWidth: 170 },
    {
      id: "NombrePrestador", label: "Nombre Prestadora de Salud", minWidth: 170,
    },
  ];
  useEffect(() => {
    fetchData();
  }, [searchTerm, paginaActual, elementosPorPagina]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/Prestadoras?page=${paginaActual}&perPage=${elementosPorPagina}&search=${searchTerm}`,
        {
          withCredentials: true,
        }
      );
      setDatos(response.data.data);
      setTotalRows(response.data.total)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPaginaActual(0);
  };


  return (
    <Box>
      {/* Título */}
      <Typography variant="h1" component="h2" textAlign={"center"}>
        Tabla de referencia de Prestadoras de salud
      </Typography>

      {/* Campo de búsqueda */}
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Buscar IPSS por el nombre..."
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{
          px: "2vh",
          pt: "20px",
          "& .MuiInputBase-input": {
            backgroundColor: colors.grey[100],
            borderRadius: "4px",
          },
        }}
      />
      {/* Componente de la tabla */}
      <StickyHeadTable
        datos={datos} // Pasa los datos obtenidos a la tabla
        columns={columns}
        page={paginaActual}
        rowsPerPage={elementosPorPagina}
        setPage={setPaginaActual}
        setRowsPerPage={setElementosPorPagina}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default PrestadorasDeSalud;
