import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../Hooks/Autorizacion";
import useFetchEstado from "../Hooks/EstadoCargue";
import { Link, useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";

const Validacion = () => {
  const Navigate = useNavigate();
  const user = useAuth();
  const {mostrarAlerta} = useAuth();
  const { estado, isLoading, NombreMuni } = useFetchEstado(user);
  const [Archivo, setArchivo] = useState(null);
  const [Nombrearchivoinvalido, setNombrearchivoinvalido] = useState(true);
  const [AlertaVisible, setAlertaVisible] = useState(false);
  const [MensajeDeAlerta, setMensajeDeAlerta] = useState("");
  const [AlertaOk, setAlertaOk] = useState(false);
  const [AlertaFail, setAlertaFail] = useState(true);
  const [AlertaBtnError, setBtnError] = useState(true);
  const [DesactivarForm, setDesactivarForm] = useState(false);
  const [BotonPDF, setBotonPDF] = useState(true);
  const [progreso, setProgreso] = useState(true);
  const [boxValidar, setBoxValidar] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // Realizar la solicitud para obtener la información del usuario y su rol
    async function fetchUserData() {
        if (user.user.role === 1) {
          Navigate("/Auditoria", { replace: true });
        } else if (user.user.role === 3) {
          Navigate("/Encuesta", { replace: true });
        }
    }
    fetchUserData();
    // Si el estado es 2, mostrar un mensaje del archivo siendo validado
    switch (estado) {
      case 2:
        setDesactivarForm(true);
        setAlertaVisible(true);
        setBtnError(false);
        mostrarAlerta({mensaje: "Su archivo no ha podido ser validado. Inténtelo nuevamente, recuerde no recargar la página durante la validación", severity: "error", mostrar: true, title: "Error"});
        break;
      case 3:
        setAlertaVisible(true);
        setAlertaFail(false);
        setNombrearchivoinvalido(true);
        mostrarAlerta({mensaje: "El último archivo ha sido verificado y contiene errores, por favor corregir y volver a intentar", severity: "error", mostrar: true, title: "Error"});
        break;
      case 4:
        setDesactivarForm(true);
        setAlertaOk(true);
        setBoxValidar("archivoValidado");
        mostrarAlerta({mensaje: "Su archivo ya ha sido verificado y ha pasado todas las validaciones, puedes continuar a subirlo firmado", severity: "success", mostrar: true, title: "Success"});
        break;
      case 5:
        setDesactivarForm(true);
        setBotonPDF(false);
        setAlertaOk(true);
        setBoxValidar("descargarPDF");
        mostrarAlerta({mensaje: "Has completado el reporte PEDT exítosamente", severity: "success", mostrar: true, title: "Success"});
        break;
      default:
        // Si el estado no coincide con ninguno de los casos anteriores
        // No hay necesidad de hacer nada
        break;
    }
  }, [estado]);

  const Manejocambios = async (e) => {
    e.preventDefault();
    setAlertaFail(true);
    setNombrearchivoinvalido(false);
    setMensajeDeAlerta("");
    setAlertaVisible(false);
    if (e.target.files && e.target.files.length > 0) {
      const ArchivoSeleccionado = e.target.files[0];
      setArchivo(ArchivoSeleccionado);
      const Nombredelarchivo = ArchivoSeleccionado.name;
      //Llamar al backend para validar el nombre del archivo
      try {
        const response = await axios.post(
          "/Validacion/Nombre",
          {
            nombreArchivo: Nombredelarchivo,
            usuario: user.user.username,
          },
          {
            withCredentials: true,
          }
        );
        if (response.data.success) {
          setArchivo(ArchivoSeleccionado); // Actualiza el estado Archivo con el archivo seleccionado
          setNombrearchivoinvalido(false);
          setAlertaVisible(false);
          setMensajeDeAlerta("");
        } else {
          setNombrearchivoinvalido(true);
          setAlertaVisible(true);
          mostrarAlerta({mensaje: "El nombre del archivo no es válido", severity: "error", mostrar: true, title: "Error"});
        }
      } catch (error) {
        setAlertaVisible(true);
        setMensajeDeAlerta(error.response.data.message);
      }
    } else {
      setNombrearchivoinvalido(true);
      setAlertaVisible(true);
      mostrarAlerta({mensaje: "No se ha seleccionado un archivo", severity: "warning", mostrar: true, title: "Warning"});
    }
  };

  const ManejoSubida = async () => {
    try {
      setBtnError(true);
      // Leer el contenido del archivo seleccionado como base64
      const reader = new FileReader();
      reader.onload = async () => {
        const contenidoBase64 = reader.result.split(",")[1]; // Obtener solo el contenido base64
        const contenidoTexto = atob(contenidoBase64); // Decodificar el contenido base64 a texto
        // Dividir el contenido del archivo en líneas
        const lineas = contenidoTexto.split("\n");
        // Verificar si el archivo tiene al menos una línea
        if (lineas.length <= 1) {
          setAlertaVisible(true);
          mostrarAlerta({mensaje: "No se permiten archivos sin registros", severity: "error", mostrar: true, title: "Error"});
          setNombrearchivoinvalido(true);
          return;
        } else {
          // Validar cada línea del archivo
          for (let i = 1; i < lineas.length; i++) {
            const linea = lineas[i].trim();
            // Dividir la línea en sus componentes usando el separador "|"
            const componentes = linea.split("|");
            // Verificar si hay exactamente 119 componentes en la línea
            if (componentes.length !== 119) {
              setAlertaVisible(true);
              setNombrearchivoinvalido(true);
              setAlertaVisible(true);
              mostrarAlerta({mensaje: `La línea ${i + 1} no contiene la estructura esperada`, severity: "error", mostrar: true, title: "Error"});
              return;
            }
          }
        }
        // Leer la primera línea del archivo
        const primeraLinea = lineas[0].trim();
        // Dividir la primera línea en sus componentes usando el separador "|"
        const componentes = primeraLinea.split("|");
        const [variable1, variable2, variable3, variable4, variable5] =
          componentes;
        const userDigits = user.user.username.slice(-5);
        // Verificar si hay exactamente 5 componentes en la primera línea
        if (
          componentes.length !== 5 ||
          !/^\d+$/.test(variable5) ||
          variable2 !== userDigits ||
          variable1 !== "1"
        ) {
          setAlertaVisible(true);
          setNombrearchivoinvalido(true);
          mostrarAlerta({mensaje: "Registro de control inválido, por favor corregir y volver a intentar", severity: "error", mostrar: true, title: "Error"});
          return;
        }
        try {
          // Enviar el contenido base64 y el nombre del archivo al backend
          const response = await axios.post(
            "/Validacion/Contenido",
            {
              archivo: contenidoBase64,
              nombreArchivo: Archivo.name, // Enviar el nombre del archivo al backend
              usuario: user.user.username,
            },
            {
              withCredentials: true,
            }
          );

          if (response.data.success) {
            setAlertaOk(true);
            mostrarAlerta({mensaje: "El archivo está siendo validado. En un momento podrá ver el resultado. Por favor, no actualice la página", severity: "success", mostrar: true, title: "Success"});
            setDesactivarForm(true);
            setNombrearchivoinvalido(true);
            setProgreso(false);
            const validacionResponse = await axios.post(
              "/Validaciones",
              {
                archivo: contenidoBase64,
                nombreArchivo: Archivo.name,
                usuario: user.user.username,
              },
              {
                withCredentials: true,
              }
            );
            setAlertaOk(true);
            mostrarAlerta({mensaje: "Su archivo ha sido validado. Actualice la página (presionar tecla F5) para ver el resultado.", severity: "success", mostrar: true, title: "Success"});
            setProgreso(true);
            console.log("eeesxx")
          }
        } catch (error) {
          console.error("Error al subir el archivo:", error);
          setAlertaVisible(true);
          setBtnError(false);
          mostrarAlerta({mensaje: "Ha ocurrido un error durante la validación. Por favor, dar clic en el botón 'Volver al estado inicial'.", severity: "error", mostrar: true, title: "Error"});
        }
      };
      reader.readAsDataURL(Archivo);
    } catch (error) {
      console.error("Error al leer el archivo:", error);
    }
  };

  function reiniciar() {
    setDesactivarForm(false);
    setAlertaVisible(false);
    setAlertaFail(true);
    setAlertaOk(false);
    setNombrearchivoinvalido(true);
    setBtnError(true);
  }

  const BotonesError = ({ onReset }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<KeyboardReturnIcon />}
        sx={{ m: 4 }}
        hidden={AlertaBtnError}
        onClick={onReset}
      >
        Volver al estado inicial
      </Button>
    );
  };

  const BoxValidar = ({ tipo }) => {
    const renderBox = () => {
      switch (tipo) {
        case "archivoValidado":
          return (
            <Box
              sx={{
                display: "flex",
                bgcolor: colors.grey[200],
                component: "section",
                flexDirection: "column",
                color: colors.grey[900],
                alignItems: "center",
                borderRadius: 15,
                boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h1"
                marginX={8}
                marginY={5}
                textAlign={"center"}
              >
                Archivo validado exitosamente
              </Typography>

              <Typography variant="h5">
                Municipio a reportar: {NombreMuni}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<ForwardRoundedIcon />}
                sx={{ m: 4 }}
                component={Link}
                to="/Subir"
              >
                {"IR A SUBIR ARCHIVO FIRMADO"}
              </Button>
            </Box>
          );

        case "descargarPDF":
          return (
            <Box
              sx={{
                display: "flex",
                bgcolor: colors.grey[200],
                component: "section",
                flexDirection: "column",
                color: colors.grey[900],
                alignItems: "center",
                borderRadius: 15,
                boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h1"
                marginX={8}
                marginY={5}
                textAlign={"center"}
              >
                Descargar archivo PDF
              </Typography>

              <Typography variant="h5">
                Municipio a reportar: {NombreMuni}
              </Typography>

              <Box
                component={Button}
                onClick={DescargaPDF}
                sx={{
                  border: 5,
                  p: 8,
                  mt: 2,
                  mb: 3.5,
                  borderRadius: 10,
                  border: "2px dashed rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  borderColor: colors.grey[900],
                  color: colors.grey[900],
                  "&:hover": {
                    borderColor: colors.grey[100], // Cambia el color del borde al hacer hover
                    color: colors.grey[100], // Cambia el color del texto al hacer hover
                    backgroundColor: colors.grey[600], // Cambia el fondo si lo deseas
                  },
                }}
              >
                {<CloudDownloadIcon fontSize="large" />}
                <Typography
                  marginLeft={2}
                  marginTop={1}
                  variant="h5"
                  display={"flex"}
                >
                  {"Clic aquí para descargar PDF"}
                </Typography>
              </Box>
            </Box>
          );

        default:
          return (
            <Box
              sx={{
                display: "flex",
                bgcolor: colors.grey[200],
                component: "section",
                flexDirection: "column",
                color: colors.grey[900],
                alignItems: "center",
                borderRadius: 15,
                boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h1"
                marginX={8}
                marginY={5}
                textAlign={"center"}
              >
                Subir archivo con los reportes
              </Typography>

              <Typography variant="h5">
                Municipio a reportar: {NombreMuni}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CheckCircleOutlineIcon />}
                sx={{ m: 1 }}
                onClick={ManejoSubida}
                hidden={Nombrearchivoinvalido || !AlertaBtnError}
              >
                {"Validar Archivo"}
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudDownloadIcon />}
                sx={{ m: 1 }}
                onClick={DescargaErrores}
                hidden={AlertaFail}
              >
                {
                  "Clic aquí para descargar archivo de errores del intento anterior"
                }
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudDownloadIcon />}
                sx={{ m: 1 }}
                onClick={DescargaPDF}
                hidden={BotonPDF}
              >
                {"Clic aquí para descargar archivo PDF"}
              </Button>

              <BotonesError onReset={reiniciar} />

              <Box
                hidden={!BotonPDF || !AlertaBtnError}
                component={Button}
                sx={{
                  border: 5,
                  p: 8,
                  mt: 2,
                  mb: 3.5,
                  borderRadius: 10,
                  border: "2px dashed rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  borderColor: colors.grey[900],
                  color: colors.grey[900],
                  "&:hover": {
                    borderColor: colors.grey[100], // Cambia el color del borde al hacer hover
                    color: colors.grey[100], // Cambia el color del texto al hacer hover
                    backgroundColor: colors.grey[600], // Cambia el fondo si lo deseas
                  },
                }}
              >
                <input
                  id="archivotxt"
                  onChange={Manejocambios}
                  type="file"
                  accept=".txt"
                  hidden={!progreso}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0, // Hace que el input sea transparente
                    cursor: "pointer", // Cambia el cursor para indicar que es clickeable
                  }}
                />
                {<CloudUploadIcon hidden={!progreso} fontSize="large" />}
                <Typography
                  hidden={!progreso}
                  marginLeft={2}
                  marginTop={1}
                  variant="h5"
                  display={"flex"}
                >
                  {Archivo ? Archivo.name : "Arrastra o sube el archivo aqui"}
                </Typography>
                <CircularProgress
                  sx={{ marginRight: 17, marginLeft: 17, mb: 2 }}
                  color="#fff"
                  hidden={progreso}
                />

                <Typography hidden={progreso} variant="h5">
                  {"Esto puede tardar un momento ..."}
                </Typography>
              </Box>
            </Box>
          );
      }
    };
    return renderBox();
  };

  const DescargaErrores = async () => {
    try {
      const response = await axios.get("/BajarErrores", {
        params: {
          user: user.user.username,
        },
        withCredentials: true,
        responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
      });

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ErroresACorregir.TXT"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // Verificar si la respuesta es un Blob
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          try {
            // Intentar parsear el Blob como JSON
            const errorData = JSON.parse(reader.result);
            mostrarAlerta({
              mensaje: errorData.message || "Error desconocido",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          } catch (e) {
            // Si no se puede parsear como JSON, mostrar un mensaje genérico
            mostrarAlerta({
              mensaje: "Error al procesar la respuesta del servidor",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          }
        };

        reader.readAsText(error.response.data); // Leer el Blob como texto
      } else {
        // Si no es un Blob, mostrar un mensaje genérico
        mostrarAlerta({
          mensaje: error.response?.data?.message || "Error desconocido",
          severity: "error",
          mostrar: true,
          title: "Error",
        });
      }
    }
  };

  const DescargaPDF = async () => {
    try {
      const response = await axios.get("/BajarPDF", {
        params: {
          correo: {
            user: user.user.user.username,
          },
        },
        withCredentials: true,
        responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
      });

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "EntregaPEDT.pdf"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // Verificar si la respuesta es un Blob
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          try {
            // Intentar parsear el Blob como JSON
            const errorData = JSON.parse(reader.result);
            mostrarAlerta({
              mensaje: errorData.message || "Error desconocido",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          } catch (e) {
            // Si no se puede parsear como JSON, mostrar un mensaje genérico
            mostrarAlerta({
              mensaje: "Error al procesar la respuesta del servidor",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          }
        };

        reader.readAsText(error.response.data); // Leer el Blob como texto
      } else {
        // Si no es un Blob, mostrar un mensaje genérico
        mostrarAlerta({
          mensaje: error.response?.data?.message || "Error desconocido",
          severity: "error",
          mostrar: true,
          title: "Error",
        });
      }
    }
  };

  return (
    <Box
      display="flex"
      height="100vh"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      overflow="auto"
    >
      <Box display="flex" justifyContent={"center"} mb={3}>
        <img src={colors.logo[100]} alt="Logo" className="w-25" />
      </Box>

      <BoxValidar tipo={boxValidar} />
    </Box>
  );
};

export default Validacion;
