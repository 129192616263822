import React, { useState, useEffect, useContext } from "react";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useAuth } from "../Hooks/Autorizacion";
import { ColorModeContext, tokens } from "../theme";
import StickyHeadTable from "../Componentes/StickyHeadTable"; // Importa el componente de la tabla

const PPNA = () => {
  const [datos, setDatos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(0); // Estado para la página actual
  const [elementosPorPagina, setElementosPorPagina] = useState(10); // Estado para filas por página
  const [totalRows, setTotalRows] = useState(0); // Total de filas
  const user = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const columns = [
    { id: "CodMunicipio", label: "Código Municipio", minWidth: 170 },
    { id: "CodTipoIdentificacion", label: "Tipo ID", minWidth: 170 },
    { id: "NumeroIdentificacion", label: "Número de Documento", minWidth: 170 },
    {id: "nombre", label: "Nombre", minWidth: 170 },
    
  ];

  useEffect(() => {
    fetchData();
  }, [searchTerm, paginaActual, elementosPorPagina]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/PPNA?page=${paginaActual}&perPage=${elementosPorPagina}&search=${searchTerm}&user=${user.user.username}`, { withCredentials: true }
      );
      // Transforma los datos para incluir el campo "nombreCompleto"
      const transformedData = response.data.data.map((dato) => ({
        ...dato,
        nombre: [
          dato.PrimerNombreUsuario,
          dato.PrimerApellidoUsuario,
          dato.SegundoApellidoUsuario,
        ]
          .filter(Boolean) // Elimina valores falsy
          .join(" ")
          .trim(),
      }));
      setDatos(transformedData);
      setTotalRows(response.data.total)
      
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPaginaActual(0);
  };

  return (
    <Box >
      {/* Título */}
      <Typography variant="h1" component="h2" textAlign={"center"}>
        Tabla de referencia de Personas vinculadas
      </Typography>

      {/* Campo de búsqueda */}
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Buscar por documento de identificación..."
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{
          px: "2vh",
          pt: "20px",
          "& .MuiInputBase-input": {
            backgroundColor: colors.grey[100],
            borderRadius: "4px",
          },
        }}
      />

      {/* Componente de la tabla */}
      <StickyHeadTable
        datos={datos} // Pasa los datos obtenidos a la tabla
        columns={columns}
        page={paginaActual}
        rowsPerPage={elementosPorPagina}
        setPage={setPaginaActual}
        setRowsPerPage={setElementosPorPagina}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default PPNA;
