import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import logoDark from ".//Imgs/logoDark.png";
import logoLight from ".//Imgs/logoLight.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#018d38", // Color primario 1
          200: "#0b5640", // Color primario 2
          300: "#0b5640", // Puedes ajustar estos valores según tu preferencia
          400: "#0b5640",
          500: "#018d38",
          600: "#0b5640",
          700: "#018d38",
          800: "#0b5640",
          900: "#ffffff",
        },
        greenAccent: {
          100: "#ffde00", // Color secundario 1
          200: "#8FCAF0", // Color secundario 2
          300: "#f28e18", // Color secundario 3
          400: "#a90f09", // Color secundario 4
          500: "#8e5f00", // Color secundario 5
          600: "#8e5f00", // Color secundario 6
          700: "#B0942B", // Color secundario 7
          800: "#8b4a97", // Color secundario 8
          900: "#33245c", // Color secundario 9
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        logo: {
          100: logoDark,
        },
      }
    : {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#018d38", // Color primario 1
          200: "#0b5640", // Color primario 2
          300: "#0b5640", // Puedes ajustar estos valores según tu preferencia
          400: "#0b5640",
          500: "#018d38",
          600: "#0b5640",
          700: "#018d38",
          800: "#0b5640",
          900: "#018d38",
        },
        greenAccent: {
          100: "#3561ab", // Color secundario 1
          200: "#8FCAF0", // Color secundario 2
          300: "#f28e18", // Color secundario 3
          400: "#a90f09", // Color secundario 4
          500: "#ffde00", // Color secundario 5
          600: "#8e5f00", // Color secundario 6
          700: "#B0942B", // Color secundario 7
          800: "#8b4a97", // Color secundario 8
          900: "#33245c", // Color secundario 9
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        logo: {
          100: logoLight,
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[600],
            },
            secondary: {
              main: colors.primary[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[800],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Prompt", "serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Prompt", "serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Prompt", "serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Prompt", "serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Prompt", "serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Prompt", "serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Prompt", "serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState('dark');
  const location = useLocation();

  useEffect(() => {
    const cookies = document.cookie.split('; ');
    const themeCookieValue = cookies.find((row) => row.startsWith('theme='));

    if (themeCookieValue) {
      const themeValue = themeCookieValue.split('=')[1];
      if (themeValue === 'dark' || themeValue === 'light') {
        setMode(themeValue);
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== '/Login') {
      const syncThemeWithServer = async () => {
        try {
          await axios.post(
            '/theme',
            { theme: mode },
            { withCredentials: true }
          );
        } catch (error) {
          console.error('Error syncing theme with server:', error);
        }
      };

      syncThemeWithServer();
    }
  }, [mode, location.pathname]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};