import React from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { tokens } from "../theme";
import { useAuth } from "../Hooks/Autorizacion";

const Auditoria = () => {
  const { mostrarAlerta } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const HandleConsolidado = async (e) => {
    try {
      const response = await axios.get(
        "/GenerarConsolidado",
        {
          responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
          withCredentials: true, // Incluye correctamente la cookie
        }
      );

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(response.data);

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ConsolidadoPEDT.TXT"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();
      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
      mostrarAlerta({
        mensaje: "Descargando el archivo",
        severity: "success",
        mostrar: true,
        title: "Success",
      });
    } catch (error) {
      // Verificar si la respuesta es un Blob
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          try {
            // Intentar parsear el Blob como JSON
            const errorData = JSON.parse(reader.result);
            mostrarAlerta({
              mensaje: errorData.message || "Error desconocido",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          } catch (e) {
            // Si no se puede parsear como JSON, mostrar un mensaje genérico
            mostrarAlerta({
              mensaje: "Error al procesar la respuesta del servidor",
              severity: "error",
              mostrar: true,
              title: "Error",
            });
          }
        };

        reader.readAsText(error.response.data); // Leer el Blob como texto
      } else {
        // Si no es un Blob, mostrar un mensaje genérico
        mostrarAlerta({
          mensaje: error.response?.data?.message || "Error desconocido",
          severity: "error",
          mostrar: true,
          title: "Error",
        });
      }
    }
  };

  return (
    <Box
      display="flex"
      height="100vh"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      overflow="auto"
    >
      <Box display="flex" justifyContent={"center"} mb={3}>
        <img src={colors.logo[100]} alt="Logo" className="w-25" />
      </Box>

      <Box
        sx={{
          display: "flex",
          bgcolor: colors.grey[200],
          component: "section",
          flexDirection: "column",
          color: colors.grey[900],
          alignItems: "center",
          borderRadius: 15,
          boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="h1" marginX={8} marginY={5} textAlign={"center"}>
          DESCARGAR CONSOLIDADO ACTUAL
        </Typography>

        <Box
          component={Button}
          onClick={HandleConsolidado}
          sx={{
            border: 5,
            p: 8,
            mt: 2,
            mb: 3.5,
            borderRadius: 10,
            border: "2px dashed rgba(0, 0, 0, 0.3)",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            borderColor: colors.grey[900],
            color: colors.grey[900],
            "&:hover": {
              borderColor: colors.grey[100], // Cambia el color del borde al hacer hover
              color: colors.grey[100], // Cambia el color del texto al hacer hover
              backgroundColor: colors.grey[600], // Cambia el fondo si lo deseas
            },
          }}
        >
          {<CloudDownloadIcon fontSize="large" />}
          <Typography
            marginLeft={2}
            marginTop={1}
            variant="h5"
            display={"flex"}
          >
            {"Clic aquí para descargar Consolidado"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Auditoria;
