import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../Hooks/Autorizacion";
import { useNavigate } from "react-router-dom";
import Carrousel from "../Componentes/CarrouselFondo";
import CambiarContraseñaForm from "../Componentes/FormContraNueva";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

const Login = () => {
  const [NombreUsuario, setNombreUsuario] = useState("");
  const [Contraseña, setContraseña] = useState("");
  const [inhabilitarform, setInhabilitarForm] = useState(false);
  const [mostrarFormularioCambio, setMostrarFormularioCambio] = useState(false);
  const [OcultarMensajeInvalido, setOcultarMensajeInvalido] = useState(true);
  const [mensajeError, setMensajeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [NombreUsuarioVacio, setNombreUsuarioVacio] = useState(false);
  const [ContraseñaVacia, setContraseñaVacia] = useState(false);
  const { login } = useAuth();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [inhabilitarBoton, setinhabilitarBoton] = useState(false);
  const [envioExitoso, setEnvioExitoso] = useState(false);
  const [mostrarPrimerFormulario, setMostrarPrimerFormulario] = useState(true);
  const [Correo, setCorreo] = useState("");
  const [intentos, setintentos] = useState(0);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (user) {
      navigate("/Validar", { replace: true });
    }
  }, [user, navigate]);

  const handlechangeUser = (e) => {
    setNombreUsuario(e.target.value);
    setOcultarMensajeInvalido(true);
    setNombreUsuarioVacio(false);
  };

  const handlechangePassword = (e) => {
    setContraseña(e.target.value);
    setOcultarMensajeInvalido(true);
    setContraseñaVacia(false);
  };

  const handlechangeOTP = (e) => {
    setOTP(e.target.value);
    setOcultarMensajeInvalido(true);
    setContraseñaVacia(false);
    if (OTP.length === 6) {
      setinhabilitarBoton(false);
    } else {
      setinhabilitarBoton(true);
    }
  };

  const handleVerificarOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/login/VerificarOTP",
        {
          userName: NombreUsuario,
          OTPrecibido: OTP,
        },
        { withCredentials: true }
      );
      if (response.data.success) {
        const primerIngresoResponse = await axios.get(
          "/login/PrimerInicio",
          {
            params: {
              NombreUsuario: NombreUsuario,
            },
          }
        );
        if (primerIngresoResponse.data.firstLogin) {
          setEnvioExitoso(false);
          setMostrarFormularioCambio(true);
        } else {
          await login(response.data.user);
        }
      } else {
        setOTP("");
        setMensajeError(
          response.data.message || "Código de verificación incorrecto"
        );
        setOcultarMensajeInvalido(false);
      }
    } catch (error) {}
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setOcultarMensajeInvalido(true);
    if (!NombreUsuario.trim()) {
      setNombreUsuarioVacio(true);
      return;
    }
    if (!Contraseña.trim()) {
      setContraseñaVacia(true);
      return;
    }
    try {
      setLoading(true);
      setInhabilitarForm(true);
      const captcha = await axios.post(
        "/login/recaptcha",
        {
          token: token, // Pasar el token reCAPTCHA al backend
        }
      );
      const response = await axios.post("/login", {
        NombreUsuario: NombreUsuario,
        Contraseña: Contraseña,
        token: token, // Pasar el token reCAPTCHA al backend
      });
      if (response.data.success) {
        setMostrarPrimerFormulario(false);
        setEnvioExitoso(true);
        setCorreo(response.data.correo);
        setinhabilitarBoton(true);
      } else {
        incrementarIntento(); // Incrementar el contador de intentos
        if (intentos === 4) {
          // Cuando se llegue a 5 intentos
          setinhabilitarBoton(true); // Inhabilitar el formulario
          setMensajeError("Demasiados intentos de inicio de sesión"); // Establecer mensaje de error
          setOcultarMensajeInvalido(false); // Mostrar el mensaje de error
        } else {
          setMensajeError(
            response.data.message || "Usuario o contraseña incorrectos"
          );
          setOcultarMensajeInvalido(false);
          setNombreUsuario("");
          setContraseña("");
          setInhabilitarForm(false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const incrementarIntento = () => {
    setintentos((prevIntentos) => prevIntentos + 1); // Incrementar el contador de intentos
  };

  const handleCambioContraseña = async (nuevaContraseña) => {
    try {
      setLoading(true);
      setInhabilitarForm(true);
      const response = await axios.post(
        "/login/CambioContra",
        {
          NombreUsuario: NombreUsuario,
          ContraseñaNueva: nuevaContraseña,
        }
      );
      if (response.data.success) {
        await login(response.data.user);
      } else {
        console.error("error al cambiar la contraseña");
        setMensajeError(
          response.data.message || "Error al cambiar la contraseña"
        );
        setOcultarMensajeInvalido(false);
        setInhabilitarForm(false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeDrs4pAAAAAChf-cH_0Wja__nU2wW1x0-XN0wP">
      <div>
        <Carrousel />
        <div className="d-flex align-items-center justify-content-center vh-100 mw-100">
          <div className="d-flex align-items-center justify-content-center flex-row w-75">
            {mostrarPrimerFormulario && (
              <form
                autoComplete="off"
                onSubmit={handleLogin}
                className="d-flex flex-column align-items-center justify-content-center mx-3 w-50"
                style={{
                  background: "rgba(7, 137, 48, 0.3)",
                  padding: "5%",
                  borderRadius: "20px",
                }}
              >
                <div className="mb-3 text-light text-center">
                  <label
                    htmlFor="NombreUsuario"
                    className="form-label text-center"
                  >
                    Usuario
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="NombreUsuario"
                    maxLength={50}
                    placeholder="Ingrese su usuario:"
                    value={NombreUsuario}
                    onChange={handlechangeUser}
                    disabled={inhabilitarform}
                  />
                </div>
                <div className="mb-3 text-light text-center">
                  <label
                    htmlFor="Contraseña"
                    className="form-label text-center"
                  >
                    Contraseña
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="Contraseña"
                    maxLength={16}
                    placeholder="Ingrese su contraseña:"
                    value={Contraseña}
                    onChange={handlechangePassword}
                    disabled={inhabilitarform}
                  />
                </div>
                <div className="form-group text-center">
                  <button
                    className="btn btn-success"
                    type="submit"
                    disabled={inhabilitarform}
                  >
                    Iniciar Sesión
                  </button>
                </div>
                <div
                  className="alert alert-danger my-3 text-center"
                  role="alert"
                  hidden={OcultarMensajeInvalido}
                >
                  {mensajeError}
                </div>
                {NombreUsuarioVacio && (
                  <div
                    className="alert alert-danger my-3 text-center"
                    role="alert"
                  >
                    El campo de usuario no puede estar vacío
                  </div>
                )}
                {ContraseñaVacia && (
                  <div
                    className="alert alert-danger my-3 text-center"
                    role="alert"
                  >
                    El campo de contraseña no puede estar vacío
                  </div>
                )}
                {loading && (
                  <div className="spinner-border text-light mt-3" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                <GoogleReCaptcha onVerify={(token) => setToken(token)} />
              </form>
            )}
            {envioExitoso && (
              <form
                autoComplete="off"
                onSubmit={handleVerificarOTP}
                className="d-flex flex-column align-items-center justify-content-center mx-3 w-50"
                style={{
                  background: "rgba(7, 137, 48, 0.3)",
                  padding: "5%",
                  borderRadius: "20px",
                }}
              >
                <div className="mb-3 text-light text-center">
                  <label htmlFor="OTP" className="form-label text-center">
                    Código de verificación enviado a {Correo}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="OTP"
                    maxLength={7}
                    placeholder="Ingresa código:"
                    value={OTP}
                    onChange={handlechangeOTP}
                  />
                </div>
                <div
                  className="alert alert-danger my-3 text-center"
                  role="alert"
                  hidden={OcultarMensajeInvalido}
                >
                  {mensajeError}
                </div>
                <div className="form-group text-center">
                  <button
                    className="btn btn-success"
                    type="submit"
                    disabled={inhabilitarBoton}
                    hidden={!inhabilitarform}
                  >
                    Verificar código
                  </button>
                </div>
              </form>
            )}
            {mostrarFormularioCambio && (
              <CambiarContraseñaForm onSubmit={handleCambioContraseña} />
            )}
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
