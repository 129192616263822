import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useFetchUserData = (user) => {
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Realizar la solicitud para obtener la información del usuario y su rol
    async function fetchUserData() {
        if (user.user.role === 2) {
          Navigate("/Validar", { replace: true });
        } else if (user.user.role === 3) {
          Navigate("/Encuesta", { replace: true });
        }
    }
    fetchUserData();
  }, [user, Navigate]);

  return isLoading;
};

export default useFetchUserData;
